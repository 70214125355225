import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Hypothekenkredit = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was ist ein Hypothekenkredit?" showCalc={false} />
            <Article>
                <p>
                    Hypothekenkredite werden von Banken für die Finanzierung von Immobilien vergeben. Die Hypothek dient
                    der jeweiligen Bank dabei zur Absicherung, sollte der Kredit nicht zurückgezahlt werden können. Ein
                    Hypothekenkredit ist also ein langfristiger{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>
                    , der durch die Eintragung einer Hypothek ins Grundbuch gesichert wird. Die Bank erhält dabei das
                    Grundpfandrecht und die Immobilie dient dabei als Pfand. Sobald der Kredit abbezahlt ist, wird der
                    Eintrag der Bank im Grundbuch wieder gelöscht.
                </p>
                <hr />

                <h2>Was heißt das praktisch für Kreditnehmer*innen?</h2>
                <p>
                    Wenn du einen Kredit aufnimmst, um eine Immobilie zu finanzieren, ist das in der Regel ein Kredit,
                    der grundbücherlich gesichert wird. Die Bank stellt auf diese Weise sicher, dass sie die Immobilie
                    verwerten kann, solltest du deine monatlichen Raten und Zinsen auch in einer Nachfrist nicht mehr
                    bezahlen können. Das ist klar, aber einige Fragen bleiben noch offen. Diese wollen wir in diesem
                    Artikel beantworten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Welche Kosten dich erwarten</h2>
                <p>
                    Wie jeder Immobilienkredit ist ein Hypothekenkredit natürlich mit Kosten verbunden. Eine
                    hypothekarische Grundbucheintragung kostet wie jede Eintragung ins Grundbuch 1,2% des einzutragenden
                    Kreditbetrags. Aber es kann noch weitere Kosten geben, wie zum Beispiel die
                    Nebengebührensicherstellung. Diese ist allerdings von deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    abhängig. Die Nebengebührensicherstellung kann bis zu 30% der Eintragungskosten betragen, insgesamt
                    zahlst du also bis zu 1,56% des Betrags deines Hypothekenkredits. Wenn du über eine gute{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    verfügst, verzichtet die Bank aber meist auf diesie zusätzlichen Kosten. Doch das ist von Bank zu
                    Bank unterschiedlich, weshalb wir wie immer ausdrücklich dazu raten, dich im im Vorhinein über
                    mögliche Konditionen zu informieren. Ein Vergleich verschiedener Angebote ist wichtig, um unnötige
                    Kosten zu sparen. Hole dir dafür Unterstützung von Expert*innen, die für dich die Angebote mehrerer
                    Banken miteinander vergleichen, um das beste für dich herauszuholen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Sprich mit einem Experten!
                    </a>
                </p>
                <hr />

                <h2>Vorzeitige Kündigung bzw. Tilgung</h2>
                <p>
                    Man kann in bestimmten Fällen die Hypothek früher löschen bzw. kündigen. Um den Hypothekenkredit
                    frühzeitig aus dem Grundbuch zu löschen, müssen allerdings einige Voraussetzungen erfüllt sein. Die
                    wichtigste ist dabei eine gute{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>
                    . Ob die Bank auch bei guter{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    einer vorzeitigen Löschung der Hypothek zustimmt, hängt jedoch auch von der Höhe deines verbliebenen
                    Kreditbetrags ab. Auch deine bestehende Beziehung zur Bank spielt eine Rolle. Eine weitere
                    Möglichkeit besteht, wenn du über andere Sicherheiten zum Tauschen verfügst. Das erhöht deine
                    Chancen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Finanzberatung anfordern
                    </a>
                </p>
                <hr />

                <h2>Simultanhypothek – was ist das?</h2>
                <p>
                    Manchmal kann es sinnvoll sein, eine Zusatz- bzw. Simultansicherheit in Betracht zu ziehen. Wenn du
                    beispielsweise schon über eine lastenfreie Immobilie verfügst, kannst du diese für deinen neuen
                    Hypothekenkredit zur Verfügung stellen. Es ist also möglich, eine Hypothek auf ein abbezahltes Haus
                    oder eine bereits abbezahlte Wohnung aufzunehmen. Das macht man meistens aber nur, wenn man über
                    keine Barmittel für die neue Immobilie verfügt und man dadurch bessere Konditionen erhalten kann.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was passiert, wenn man nicht mehr zahlen kann?</h2>
                <p>
                    Dadurch, dass der Hypothekenkredit deiner Bank ihr als Absicherung dient, droht im schlimmsten Fall
                    tatsächlich die Zwangsversteigerung deiner Immobile. Damit es nicht so weit kommt, sollte man
                    allerdings rechtzeitig Kontakt mit der Bank aufnehmen. Das heißt, sobald du wirklich befürchten
                    musst, den Kredit nicht mehr zahlen zu können. Zögere also nicht, wenn du merkst, dass du in
                    finanziellen Schwierigkeiten steckst. Sprich offen mit deiner Bank darüber. Gemeinsam kann man
                    versuchen, eine Lösung zu finden. Es gibt mehrere Optionen, wie zum Beispiel eine Reduktion oder
                    sogar eine Stundung der monatlichen Raten. Auch eine Umschuldung kann eine Möglichkeit sein, eine
                    Zwangsversteigerung deiner Immobilie rechtzeitig zu verhindern.
                </p>
                <p>
                    Wie jeder{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    ist auch der Hypothekenkredit eine langfristige und weitreichende Entscheidung, die gut überlegt
                    sein sollte. Vergleiche unbedingt die Angebote unterschiedlicher Banken miteinander und lasse dich
                    von Expert*innen beraten, um das beste für dich herauszuholen.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"hypothekenkredit"}></BreadcrumbList>
            <ArticleStructuredData page={"hypothekenkredit"} heading={"Was ist ein Hypothekenkredit?"} />
        </Layout>
    );
};

export default Hypothekenkredit;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.hypothekenkredit", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
